<template>
  <div class="customerNotificationStepZero">
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      v-show="showStep"
    >
      <form>
        <div class="form-group row mb-4">
          <label
            for="notificationMedia"
            class="form-label col-12 col-md-1 mt-2"
          >{{ $t("contactNotificationMedia") }}</label>
          <Multiselect
            id="notificationMedia"
            v-model="notificationMediaType"
            tag-placeholder="Not found"
            placeholder="Notification Media"
            :options="notificationMedia"
            select-label=""
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="col-12 col-md-10"
          />
          <div class="col-12 col-md-7" />
        </div>
        <div class="form-group row mb-4">
          <label
            for="installationTypes"
            class="form-label col-12 col-md-1 mt-2"
          >{{ $t("customerNotification.installationTypes") }}</label>
          <Multiselect
            id="installationTypes"
            v-model="filterInstallationType"
            tag-placeholder="Not found"
            placeholder="Type"
            :options="installationTypes"
            select-label=""
            label="name"
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            class="col-12 col-md-10"
          />
          <div class="col-12 col-md-7" />
        </div>
        <div class="form-group row mb-4">
          <label
            for="hostingAndSupporting"
            class="form-label col-12 col-md-1 mt-2"
          >{{ $t("customerNotification.agreements") }}</label>
          <Multiselect
            id="hostingAndSupporting"
            v-model="agreementType"
            tag-placeholder="Not found"
            placeholder="Agreement"
            :options="hostingAndSupportAgreement"
            select-label=""
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="col-12 col-md-10"
          />
          <div class="col-12 col-md-7" />
        </div>
        <div class="form-group row mb-4">
          <label
            for="sponsors"
            class="form-label col-12 col-md-1 mt-2"
          >{{
            $t("customerNotification.sponsors")
          }}</label>
          <Multiselect
            id="sponsors"
            v-model="filterInstallationSponsors"
            tag-placeholder="Not found"
            placeholder="Sponsors"
            :options="sponsors"
            select-label=""
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="col-12 col-md-10"
          />
          <div class="col-12 col-md-7" />
        </div>
        <div class="form-group row mb-4">
          <label
            for="roles"
            class="form-label col-12 col-md-1 mt-2"
          >{{
            $t("customerNotification.roles")
          }}</label>
          <Multiselect
            id="roles"
            v-model="selectedRoles"
            tag-placeholder="Not found"
            placeholder="Roles"
            :options="roles"
            select-label=""
            :multiple="true"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="col-12 col-md-10"
          />
          <div class="col-12 col-md-7" />
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          type="button"
          :disabled="
            notificationMediaType === null ||
              (notificationMediaType != null &&
              notificationMediaType.length < 1) ||
              filterInstallationType == null ||
              (filterInstallationType != null &&
              filterInstallationType.length < 1)
          "
          @click="nextStep"
        >
          <span>
            {{ $t("next") }}
          </span>
          <font-awesome-icon
            class="ml-1"
            icon="arrow-right"
          />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerNotificationStepZero",
  components: {
    Multiselect: () => import("vue-multiselect"),
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    stepOneObj: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showStep: false,
      filterInstallationType: [],
      installationTypes: [],
      agreementType: [],
      filterInstallationSponsors: [],
      sponsors: [],
      selectedRoles: [],
      loading: true,
      notificationMediaType: null,
    };
  },
  computed: {
    hostingAndSupportAgreement() {
      return ["Winter Season", "Summer Season", "Hardware Maintenance"];
    },
    notificationMedia() {
      return ["Email", "SMS"];
    },
    roles() {
      return [
        "Assistant Managing Directors",
        "Delivery",
        "Electrical",
        "Finance",
        "Head Of",
        "Managing Director",
        "Marketing",
        "Information Technology",
        "Operations",
        "Product Manager",
        "Project Manager",
        "Purchasing",
        "Guest Service",
      ];
    },
  },
  watch: {
    active(val) {
      this.showStep = val;
    },
  },
  created() {
    this.showStep = this.active;
    this.getInstallationTypes();
    this.getInstallationSponsors();
    if (this.stepOneObj) {
      this.filterInstallationType = this.stepOneObj.types;
      this.filterInstallationSponsors = this.stepOneObj.sponsors;
      this.selectedRoles = this.stepOneObj.roles;
      this.agreementType = this.stepOneObj.agreements;
      this.notificationMediaType = this.stepOneObj.notificationMedia
    }
  },
  methods: {
    getInstallationTypes() {
      this.axios
        .get("/Installation/GetInstallationTypes")
        .then((response) => {
          // let result = response.data;
          this.installationTypes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInstallationSponsors() {
      this.axios
        .get("/Installation/GetInstallationSponsors")
        .then((response) => {
          this.sponsors = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    nextStep() {
      let stepObj = {
        types: this.filterInstallationType,
        sponsors:
          this.filterInstallationSponsors != null
            ? this.filterInstallationSponsors
            : [],
        roles: this.selectedRoles != null ? this.selectedRoles : [],
        agreements: this.agreementType != null ? this.agreementType : [],
        notificationMedia: this.notificationMediaType
      };
      this.$emit("goStepTwo", stepObj);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
